import { ApiDimension } from "@incendium/api";
import { friendlyDate, friendlyDateTime, friendlyMonth } from "Helpers/dates";
import { TDimensionMetricMap } from "features/analytics/types/types";

const dimensionMap: { [key in ApiDimension]?: TDimensionMetricMap } = {
  [ApiDimension.DIMENSION_NOT_SET]: {
    originalName: "not set",
    name: "not set",
    description: "",
  },
  [ApiDimension.DIMENSION_SESSION_START_BY_DAY]: {
    originalName: "session_start_by_day",
    name: "Session Start by Day",
    description: "start of the day the session happened on",
    format: (v) => friendlyDate(`${v}`),
  },
  [ApiDimension.DIMENSION_LOCATION]: {
    originalName: "location",
    name: "Page Location",
    description: "pageview location",
  },
  [ApiDimension.DIMENSION_BROWSER]: {
    originalName: "browser",
    name: "Browser",
    description: "leads browser",
  },
  [ApiDimension.DIMENSION_BROWSER_VERSION]: {
    originalName: "browser_version",
    name: "Browser Version",
    description: "leads browser version",
  },
  [ApiDimension.DIMENSION_BROWSER_WITH_VERSION]: {
    originalName: "browser_with_version",
    name: "Browser & Version",
    description: "leads browser & version",
  },
  [ApiDimension.DIMENSION_OS]: {
    originalName: "os",
    name: "Operating System",
    description: "",
  },
  [ApiDimension.DIMENSION_DEVICE]: {
    originalName: "device",
    name: "Device",
    description: "is mobile or desktop",
  },
  [ApiDimension.DIMENSION_CHANNEL]: {
    originalName: "channel",
    name: "Channel",
    description: "channel that brought in a session",
  },
  [ApiDimension.DIMENSION_SOURCE_BUCKET]: {
    originalName: "source_bucket",
    name: "Traffic Bucket",
    description:
      "Grouped together sources etc, differs to channels as a session can be in more than 1 bucket.",
  },
  [ApiDimension.DIMENSION_USER_TYPE]: {
    originalName: "unique_user_type",
    name: "User Type",
    description: "this is new vs returning leads",
  },
  [ApiDimension.DIMENSION_COUNTRY]: {
    originalName: "country",
    name: "Country",
    description: "by lead country",
  },
  [ApiDimension.DIMENSION_REGION]: {
    originalName: "region",
    name: "Region",
    description: "by lead region",
  },
  [ApiDimension.DIMENSION_CITY]: {
    originalName: "city",
    name: "City",
    description: "by lead city",
  },
  [ApiDimension.DIMENSION_LON_LAT]: {
    originalName: "longitude_latitude",
    name: "Longitude & Latitude",
    description: "by longitude - latitude",
  },
  [ApiDimension.DIMENSION_SESSION_AUDIENCE]: {
    originalName: "session_audience_name",
    name: "Static Audience Name",
    description: "by the audiences a a session was (static)",
  },
  [ApiDimension.DIMENSION_LEAD_AUDIENCE]: {
    originalName: "lead_audience_name",
    name: "Dynamic Audience Name",
    description: "by the audiences a lead can be (dynamic)",
  },
  [ApiDimension.DIMENSION_SOURCE]: {
    originalName: "source",
    name: "Source",
    description: "source that brought in a session",
  },
  [ApiDimension.DIMENSION_MEDIUM]: {
    originalName: "medium",
    name: "Medium",
    description: "medium that brought in a session",
  },
  [ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN]: {
    originalName: "campaign",
    name: "Campaign",
    description: "Campaign that brought in a session",
  },
  [ApiDimension.DIMENSION_CAMPAIGN]: {
    originalName: "personsalisation_campaign",
    name: "Personsalisation Campaign",
    description: "Personsalisation Campaign that runs a personalisation",
  },
  [ApiDimension.DIMENSION_PERSONALISATION]: {
    originalName: "personsalisation",
    name: "Personsalisation",
    description: "Personsalisation that is part of a campaign",
  },
  [ApiDimension.DIMENSION_CAMPAIGN_TYPE]: {
    originalName: "personsalisation_campaign_type",
    name: "Personsalisation Campaign Type",
    description: "Type of Campaign that runs a personalisation",
  },
  [ApiDimension.DIMENSION_AB_CAMPAIGN]: {
    originalName: "ab_campaign",
    name: "A/B Campaign",
    description: "A/B Campaign that runs a personalisation",
  },
  [ApiDimension.DIMENSION_CAMPAIGN_LEAD_STATUS]: {
    originalName: "campaign_type",
    name: "Campaign Control / Variant",
    description: "whether lead is in control or Variant",
  },
  [ApiDimension.DIMENSION_CONTENT]: {
    originalName: "content",
    name: "Content",
    description: "content that brought in a session",
  },
  [ApiDimension.DIMENSION_TERM]: {
    originalName: "term",
    name: "Term",
    description: "term that brought in a session",
  },
  [ApiDimension.DIMENSION_PATH]: {
    originalName: "path",
    name: "Path",
    description: "pageview display_page",
  },
  [ApiDimension.DIMENSION_PRODUCT_NAME]: {
    originalName: "page_product_name",
    name: "Product Name",
    description: "name of product linked to page",
  },
  [ApiDimension.DIMENSION_PRODUCT_CATEGORY]: {
    originalName: "page_product_category",
    name: "Product Category",
    description: "category of product linked to page",
  },
  [ApiDimension.DIMENSION_PRODUCT_BRAND]: {
    originalName: "page_product_brand",
    name: "Product Brand",
    description: "brand of product linked to page",
  },
  [ApiDimension.DIMENSION_JOB_TITLE]: {
    originalName: "page_job_title",
    name: "Job Title",
    description: "title of job linked to page",
  },
  [ApiDimension.DIMENSION_JOB_CATEGORY]: {
    originalName: "page_job_category",
    name: "Job Category",
    description: "category of job linked to page",
  },
  // [ApiDimension.DIMENSION_PRODUCT]: "DimensionProduct",
  // [ApiDimension.DIMENSION_JOB]: "DimensionJob",
  [ApiDimension.DIMENSION_LOCATION_PAGE_TYPE]: {
    originalName: "location_tag",
    name: "Page Location Tag",
    description: "pageview location tag",
  },
  [ApiDimension.DIMENSION_LANDING_PAGE_PATH]: {
    originalName: "landing_page_path",
    name: "Landing Page Path",
    description: "pageview display_page of session landing page",
  },
  [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION]: {
    originalName: "page_landing_page_location",
    name: "Landing Page Location",
    description: "First Page of a visit",
  },
  [ApiDimension.DIMENSION_SESSION_LANDING_PAGE_LOCATION]: {
    originalName: "landing_page_location",
    name: "Session Landing Page Location",
    description:
      "location of session landing page, this is for the entire session",
  },
  [ApiDimension.DIMENSION_SESSION_LANDING_PAGE_PATH]: {
    originalName: "landing_page_path",
    name: "Session Landing Page Path",
    description: "path of session landing page, this is for the entire session",
  },
  [ApiDimension.DIMENSION_LANDING_PAGE_TYPE]: {
    originalName: "landing_page_location_tag",
    name: "Landing Page Location Tag",
    description: "landing pageview location tag",
  },
  [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION_TAGGER]: {
    originalName: "landing_page_data_key",
    name: "Landing Page Data Key",
    description: "Key used for landing page tagging",
  },
  [ApiDimension.DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE]: {
    originalName: "landing_page_data_value",
    name: "Landing Page Data Value",
    description: "by landing page data value captured for key for page",
  },
  [ApiDimension.DIMENSION_LOCATION_TAGGER]: {
    originalName: "page_data_key",
    name: "Page Data Key",
    description: "Key used for page tagging",
  },
  [ApiDimension.DIMENSION_LOCATION_TAGGER_VALUE]: {
    originalName: "page_data_value",
    name: "Page Data Value",
    description: "by page data value captured for key for page",
  },
  [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_TYPE]: {
    originalName: "landing_next_page_location_tag",
    name: "Landing Page Next Page Location Tag",
    description: "Next landing pageview location tag",
  },
  // [ApiDimension.DIMENSION_LANDING_PAGE_META_VALUE]: {
  //   originalName: "landing_page_data_value",
  //   name: "Landing Page Data Value",
  //   description: "by session landing page data value captured for that page",
  // },
  [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_PATH]: {
    originalName: "landing_next_page_path",
    name: "Landing Page Next Page Path",
    description:
      "pageview display_page of session landing_next page, important, all metrics for this dimension are related to it's landing page and should be used in conjection with landing_page dimension, for example pageview count will be the number of pagesviews that started on X location and moved onto this one",
  },
  [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION]: {
    originalName: "landing_next_page_location",
    name: "Landing Page Next Page Location",
    description:
      "location of session landing_next page, important, all metrics for this dimension are related to it's landing page and should be used in conjection with landing_page dimension, for example pageview count will be the number of pagesviews that started on X location and moved onto this one",
  },
  [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER]: {
    originalName: "landing_next_page_data_key",
    name: "Landing Page Next Data Key",
    description: "Key used for landing page tagging",
  },
  [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE]: {
    originalName: "landing_next_page_data_value",
    name: "Landing Page Next Data Value",
    description: "by landing next page data value captured for key for page",
  },
  // [ApiDimension.DIMENSION_LANDING_NEXT_PAGE_META_VALUE]: {
  //   originalName: "landing_next_page_data_value",
  //   name: "Landing Page Next Data Value",
  //   description:
  //     "by session landing_next page data value captured for that page, important, all metrics for this dimension are related to it's landing page and should be used in conjection with landing_page dimension, for example pageview count will be the number of pagesviews that started on X location and moved onto this one",
  // },
  [ApiDimension.DIMENSION_SESSION_START_BY_HOUR]: {
    originalName: "session_start_by_hour",
    name: "Session Start by Hour",
    description: "start of the hour the session happened on",
  },
  [ApiDimension.DIMENSION_SESSION_START_BY_WEEK]: {
    originalName: "session_start_by_week",
    name: "Session Start by Week",
    description: "start of the week the session happened on",
  },
  [ApiDimension.DIMENSION_SESSION_START_BY_MONTH]: {
    originalName: "session_start_by_month",
    name: "Session Start by Month",
    description: "start of the month the session happened on",
    format: (v) => friendlyMonth(`${v}`),
  },
  [ApiDimension.DIMENSION_SESSION_START_BY_WEEKDAY]: {
    originalName: "session_day_of_week",
    name: "Session Day of Week",
    description: "day session happened on",
  },
  [ApiDimension.DIMENSION_MACRO_CONVERSION]: {
    originalName: "macro_conversion_name",
    name: "Conversion Name",
    description: "name of Conversion",
  },
  [ApiDimension.DIMENSION_MICRO_CONVERSION]: {
    originalName: "micro_conversion_name",
    name: "Micro Conversion Name",
    description: "name of Interaction",
  },
  [ApiDimension.DIMENSION_CRAWLER]: {
    originalName: "crawler_name",
    name: "Crawler Name",
    description: "name of crawler that brought in a session",
  },
  [ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT]: {
    originalName: "days_to_second_visit",
    name: "Days to Second Session",
    description:
      "no. of days a lead took to their second visit (returning only)",
  },
  [ApiDimension.DIMENSION_SESSION_JOURNEY]: {
    originalName: "session_journey",
    name: "User Pageview Journey",
    description: "this is the location journey per session",
  },
  [ApiDimension.DIMENSION_LEAD_STATUS]: {
    originalName: "lead_type",
    name: "Lead Type",
    description:
      "this is the current lead status (e.g mql etc. this is dynamic so a lead changes type, all thats leads metrics will reflect change)",
  },
  [ApiDimension.DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION]: {
    originalName: "days_since_prior_macro_conversion",
    name: "Days Since Conversion",
    description:
      "This is the number of days a lead had before each macro converting session, this resest at each leads macro converting session",
  },
  [ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION]: {
    originalName: "visits_since_prior_macro_conversion",
    name: "Sessions Since Conversion",
    description:
      "This is the number of sessions a lead had before each macro converting session, this resest at each leads macro converting session",
  },
  [ApiDimension.DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION]: {
    originalName: "DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION",
    name: "Days To First Conversion",
    description:
      "This is the number of days a lead had had before their first conversion",
  },
  [ApiDimension.DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION]: {
    originalName: "visits_since_prior_macro_conversion",
    name: "Sessions To First Conversion",
    description:
      "This is the number of sessions a lead had had before their first conversion",
  },
  [ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY]: {
    originalName: "converting_sessions_journey",
    name: "Converted User Pageview Journey",
    description:
      "this is the locations journey per all sessions leading up to a conversion",
  },
  [ApiDimension.DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY]: {
    originalName: "channel_macro_conversion_journey",
    name: "Converted User Channel Journey",
    description:
      "this is the channel journey to each conversion, resetting at each conversion, (note only applies to sessions with conversions)",
  },
  [ApiDimension.DIMENSION_SALES_SESSION_JOURNEY]: {
    originalName: "sales_sessions_journey",
    name: "Sales User Pageview Journey",
    description:
      "this is the locations journey per all sessions leading up to a sale",
  },
  [ApiDimension.DIMENSION_CHANNEL_SALES_JOURNEY]: {
    originalName: "channel_macro_conversion_journey",
    name: "Sales User Channel Journey",
    description:
      "this is the channel journey to each sale, resetting at each sale, (note only applies to sessions with sales)",
  },
  [ApiDimension.DIMENSION_DOMAIN]: {
    originalName: "domain",
    name: "Domain",
    description: "The sites domain",
  },
  [ApiDimension.DIMENSION_IS_RETURNING_CUSTOMER]: {
    originalName: "DIMENSION_IS_RETURNING_CUSTOMER",
    name: "Is Returning Customer",
    description: "",
  },
  [ApiDimension.DIMENSION_SESSION_START_DATE]: {
    originalName: "DIMENSION_SESSION_START_DATE",
    name: "Visit Date",
    description: "",
    format: (v) => friendlyDateTime(`${v}`),
  },
  [ApiDimension.DIMENSION_SESSION_ID]: {
    originalName: "DIMENSION_SESSION_ID",
    name: "Visit Id",
    description: "",
  },
  [ApiDimension.DIMENSION_TRANSACTION_ID]: {
    originalName: "DIMENSION_TRANSACTION_ID",
    name: "Transaction Id (order)",
    description: "",
  },
};

export const formatDimension = (dimension: ApiDimension, value: string) => {
  const m = dimensionMap[dimension];
  return m && m.format ? m.format(value) : value;
};

export const dimensionOriginalNameToEnum = (originalName: string) => {
  const keys = Object.keys(dimensionMap) as ApiDimension[];
  const foundKey = keys.find(
    (key) => dimensionMap[key]?.originalName === originalName
  );
  return foundKey || originalName;
};
export const dimensionNameToEnum = (name: string) => {
  const keys = Object.keys(dimensionMap) as ApiDimension[];
  const foundKey = keys.find((key) => dimensionMap[key]?.name === name);
  return foundKey || name;
};

export const dimensionToName = (dimension: ApiDimension) =>
  dimensionMap[dimension]?.name ||
  dimensionMap[dimensionOriginalNameToEnum(dimension) as ApiDimension]?.name ||
  dimension;
