import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Box, styled } from "@mui/material";
import AnalyticsChart from "features/analytics/components/AnalyticsChart";

const StyledWrapper = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 120px)",
  transform: "translate(-8px, -27px)!important",
  width: "calc(100% + 17px)",
  "& > .MuiBox-root": {
    height: "100%",
  },
}));

interface ITrafficTableProps {
  attributes?: ApiChartAttribute[];
}

function TrafficTable({ attributes }: ITrafficTableProps) {
  return (
    <StyledWrapper>
      <AnalyticsChart
        chart={{
          name: "Traffic Report",
          dimension: [
            ApiDimension.DIMENSION_SESSION_ID,
            ApiDimension.DIMENSION_SESSION_START_DATE,
            ApiDimension.DIMENSION_SESSION_LANDING_PAGE_PATH,
          ],
          template: ApiChartTemplate.TABLE,
          type: ApiChartType.TABLE,
          orderBy: `${ApiDimension.DIMENSION_SESSION_START_DATE}`,
          attributes: attributes || [],
          yAxisKeys: [
            {
              key: "l",
              fields: [
                ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
                ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                ApiMetric.METRIC_SESSION_SALES_COUNT,
                ApiMetric.METRIC_SESSION_REVENUE,
              ],
            },
          ],
          displayOptions: {
            noFilters: true,
            noTitle: true,
            noAttributionToggle: true,
            rowsPerPage: 20,
          },
        }}
      />
    </StyledWrapper>
  );
}

export default TrafficTable;
