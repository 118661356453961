/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiDimension;
(function (ApiDimension) {
    ApiDimension["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ApiDimension["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ApiDimension["DIMENSION_LOCATION"] = "DimensionLocation";
    ApiDimension["DIMENSION_BROWSER"] = "DimensionBrowser";
    ApiDimension["DIMENSION_OS"] = "DimensionOS";
    ApiDimension["DIMENSION_DEVICE"] = "DimensionDevice";
    ApiDimension["DIMENSION_CHANNEL"] = "DimensionChannel";
    ApiDimension["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ApiDimension["DIMENSION_COUNTRY"] = "DimensionCountry";
    ApiDimension["DIMENSION_REGION"] = "DimensionRegion";
    ApiDimension["DIMENSION_CITY"] = "DimensionCity";
    ApiDimension["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ApiDimension["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ApiDimension["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ApiDimension["DIMENSION_SOURCE"] = "DimensionSource";
    ApiDimension["DIMENSION_MEDIUM"] = "DimensionMedium";
    ApiDimension["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ApiDimension["DIMENSION_CONTENT"] = "DimensionContent";
    ApiDimension["DIMENSION_TERM"] = "DimensionTerm";
    ApiDimension["DIMENSION_PATH"] = "DimensionPath";
    ApiDimension["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ApiDimension["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ApiDimension["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ApiDimension["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ApiDimension["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ApiDimension["DIMENSION_PRODUCT"] = "DimensionProduct";
    ApiDimension["DIMENSION_JOB"] = "DimensionJob";
    ApiDimension["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ApiDimension["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ApiDimension["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ApiDimension["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ApiDimension["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ApiDimension["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ApiDimension["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ApiDimension["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ApiDimension["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ApiDimension["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ApiDimension["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ApiDimension["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ApiDimension["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ApiDimension["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ApiDimension["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ApiDimension["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ApiDimension["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ApiDimension["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ApiDimension["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ApiDimension["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ApiDimension["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ApiDimension["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ApiDimension["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ApiDimension["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ApiDimension["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ApiDimension["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ApiDimension["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ApiDimension["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ApiDimension["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ApiDimension["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ApiDimension["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ApiDimension["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ApiDimension["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ApiDimension["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ApiDimension["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ApiDimension["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ApiDimension["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ApiDimension["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ApiDimension["DIMENSION_DOMAIN"] = "DimensionDomain";
    ApiDimension["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ApiDimension["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ApiDimension["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ApiDimension["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ApiDimension["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ApiDimension["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ApiDimension["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
})(ApiDimension || (ApiDimension = {}));
export function ApiDimensionFromJSON(json) {
    return ApiDimensionFromJSONTyped(json, false);
}
export function ApiDimensionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiDimensionToJSON(value) {
    return value;
}
