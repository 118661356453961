import { Box, BoxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Glass, IGlassProps } from "Components/Glass";

const useGlassCardStyles = makeStyles<Theme, IGlassProps>((theme: Theme) => ({
  card: ({ noShadow, sx, square, noBorder }) => ({
    boxShadow: noShadow ? "none" : "rgba(201, 211, 244, 0.3) 0px 0px 20px",
    height: (sx as any)?.height || undefined,
    border: noBorder ? "none" : "1px solid rgba(201, 211, 244, 0.5)",
    borderRadius: square ? 0 : 10,
  }),
}));

export interface IGlassCardProps extends IGlassProps {
  boxProps?: BoxProps;
  disabled?: boolean;
}

function GlassCard({
  boxProps,
  disabled,
  component,
  animate,
  initial,
  exit,
  transition,
  ...props
}: IGlassCardProps) {
  const { card } = useGlassCardStyles(props);
  const animateProps = { animate, initial, exit, transition };
  return (
    <Box
      component={component}
      {...animateProps}
      sx={{
        pointerEvents: disabled ? "none" : "auto",
        position: disabled ? "relative" : undefined,
        "&::after": disabled
          ? {
              content: "''",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(255, 255, 255, 0.6)",
            }
          : undefined,
        ...props.sx,
      }}
      className={card}
    >
      <Glass noShadow {...props}>
        <Box sx={{ height: "100%" }} {...boxProps}>
          {props.children}
        </Box>
      </Glass>
    </Box>
  );
}

export default GlassCard;
