import {
  ApiChartAttribute,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { GridSize, Typography } from "@mui/material";
import { ColsCol, ColsLayout } from "Components/ColsLayout/ColsLayout";
import { Glass } from "Components/Glass";
import StyledDrawer, {
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { TrafficTable } from "features/analytics";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import {
  CampaignAnalysisAnalyzeFilters,
  CampaignAnalysisTable,
} from "features/attributionAnalytics";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { useKeyPress, useUpdateEffect } from "react-use";

interface ICampaignAnalysisExplorerProps {
  mode: "explore" | "anlyze";
  paidChannelAttributes: ApiChartAttribute[]; // from hoc
}

function CampaignAnalysisExplorer({
  mode,
  paidChannelAttributes,
}: ICampaignAnalysisExplorerProps) {
  const [colWidths, setColsWidth] = useState<GridSize[]>([9, 1, 1, 1]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [step, setStep] = useState(0);
  const [openTrafficDrawer, setOpenTrafficDrawer] = useState(false);
  const [trafficDrawerAttributes, setTrafficDrawerAttributes] = useState<
    ApiChartAttribute[]
  >([]);
  const [isShiftPressed] = useKeyPress("Shift");
  const [conversionMetric, setConversionMetric] = useState<AttributionMetric>(
    AttributionMetric.SALES
  );
  const [attributionModel, setAttributionModel] = useState<AttributionType>(
    AttributionType.FIRST
  );
  const [publisherConversion, setPublisherConversion] = useState<ApiMetric>(
    ApiMetric.METRIC_PUBLISHER_CONVERSIONS
  );

  useUpdateEffect(() => {
    if (mode === "anlyze") {
      setStep(0);
    }
    setSelectedPlatforms([]);
    setSelectedCampaigns([]);
    setSelectedGroups([]);
    setSelectedKeywords([]);
  }, [mode]);

  const setSelected = useCallback(
    (state: string[], val: string) =>
      produce(state, (draft) => {
        if (isShiftPressed) {
          const idx = draft.findIndex((d) => d === val);
          if (idx >= 0) {
            draft.splice(idx, 1);
          } else {
            draft.push(val);
          }
        } else {
          draft.length = 0;
          draft.push(val);
        }
      }),
    [isShiftPressed]
  );

  const selected = useCallback(
    (initailStep: number) => {
      switch (initailStep) {
        case 1:
          return selectedCampaigns;
        case 2:
          return selectedGroups;
        case 3:
          return selectedKeywords;
        default:
          return selectedPlatforms;
      }
    },
    [selectedPlatforms, selectedCampaigns, selectedGroups, selectedKeywords]
  );

  const onFirstColSelect = useCallback(
    (toStep: number) => (val) => {
      const sel = selected(toStep - 1);
      const idx = sel.findIndex((s) => s === val);
      let step = toStep;

      if (
        (isShiftPressed && idx >= 0 && sel.length <= 1) ||
        (!isShiftPressed && idx >= 0)
      ) {
        step = toStep - 1;
      }

      setStep(step);

      switch (step) {
        case 1:
          if (toStep === step) {
            setSelectedPlatforms((s) => setSelected(s, val));
          }
          break;
        case 2:
          if (toStep === step) {
            setSelectedCampaigns((s) => setSelected(s, val));
          }
          break;
        case 3:
          if (toStep === step) {
            setSelectedGroups((s) => setSelected(s, val));
          }
          break;
        case 4:
          if (toStep === step) {
            setSelectedKeywords((s) => setSelected(s, val));
          }
          break;

        default:
          break;
      }
    },
    [isShiftPressed, setSelected, selected]
  );

  useUpdateEffect(() => {
    switch (step) {
      case 0:
        setColsWidth([9, 1, 1, 1]);
        setSelectedPlatforms([]);
        setSelectedCampaigns([]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 1:
        setColsWidth([1, 9, 1, 1]);
        setSelectedCampaigns([]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 2:
        setColsWidth([1, 1, 9, 1]);
        setSelectedGroups([]);
        setSelectedKeywords([]);
        break;
      case 3:
        setColsWidth([1, 1, 1, 9]);
        setSelectedKeywords([]);
        break;

      default:
        break;
    }
  }, [step]);

  const onMetricClick = useCallback(
    (metric: ApiMetric, dimension: ApiDimension, dimensionValue: string) => {
      setTrafficDrawerAttributes((attr) =>
        produce(attr, (draft) => {
          draft.length = 0;
          draft.push(...paidChannelAttributes);

          if (dimension === ApiDimension.DIMENSION_SOURCE) {
            draft.push({
              key: ApiDimension.DIMENSION_SOURCE,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedPlatforms || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_SOURCE,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }

          if (dimension === ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN) {
            draft.push({
              key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedCampaigns || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }

          if (dimension === ApiDimension.DIMENSION_CONTENT) {
            draft.push({
              key: ApiDimension.DIMENSION_CONTENT,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedGroups || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_CONTENT,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }
          if (dimension === ApiDimension.DIMENSION_TERM) {
            draft.push({
              key: ApiDimension.DIMENSION_TERM,
              operator: ApiOperator.EQUAL,
              value: dimensionValue,
            });
            return;
          } else {
            (selectedKeywords || []).forEach((s) => {
              draft.push({
                key: ApiDimension.DIMENSION_TERM,
                operator: ApiOperator.EQUAL,
                value: s,
              });
            });
          }
        })
      );
      setOpenTrafficDrawer(true);
    },
    [
      selectedPlatforms,
      selectedCampaigns,
      selectedGroups,
      selectedKeywords,
      paidChannelAttributes,
    ]
  );

  useEffect(() => {
    if (!openTrafficDrawer) {
      setTrafficDrawerAttributes([]);
    }
  }, [openTrafficDrawer]);

  return (
    <>
      <Glass opacity={0.4} elevation={0}>
        {mode === "anlyze" ? (
          <CampaignAnalysisTable
            firstCols={[
              {
                key: ApiDimension.DIMENSION_SOURCE,
                text: "Platforms",
              },
              {
                key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                text: "Campaigns",
              },
              {
                key: ApiDimension.DIMENSION_CONTENT,
                text: "Sets",
              },
              {
                key: ApiDimension.DIMENSION_TERM,
                text: "Keywords",
              },
            ]}
            selecteValues={selectedPlatforms}
            headerComponent={
              <CampaignAnalysisAnalyzeFilters
                selectedPlatforms={selectedPlatforms}
                setSelectedPlatforms={setSelectedPlatforms}
                selectedCampaigns={selectedCampaigns}
                setSelectedCampaigns={setSelectedCampaigns}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                selectedKeywords={selectedKeywords}
                setSelectedKeywords={setSelectedKeywords}
              />
            }
            conversionMetric={conversionMetric}
            setConversionMetric={setConversionMetric}
            attributionModel={attributionModel}
            setAttributionModel={setAttributionModel}
            publisherConversion={publisherConversion}
            setPublisherConversion={setPublisherConversion}
            filters={[
              ...selectedPlatforms.map((sp) => ({
                key: ApiDimension.DIMENSION_SOURCE,
                value: sp,
              })),
              ...selectedCampaigns.map((sc) => ({
                key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                value: sc,
              })),
              ...selectedGroups.map((sg) => ({
                key: ApiDimension.DIMENSION_CONTENT,
                value: sg,
              })),
              ...selectedKeywords.map((sg) => ({
                key: ApiDimension.DIMENSION_TERM,
                value: sg,
              })),
            ]}
            currentStep={step}
            step={0}
          />
        ) : (
          <ColsLayout>
            <ColsCol width={colWidths[0]}>
              <CampaignAnalysisTable
                title="Publishers"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_SOURCE,
                    text: "Platforms",
                  },
                ]}
                onClick={(val) => onFirstColSelect(1)(val)}
                selecteValues={selectedPlatforms}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[]}
                currentStep={step}
                step={0}
                onTitleClick={() => setStep(0)}
                onMetricClick={onMetricClick}
                showProviderIcon
              />
            </ColsCol>
            <ColsCol width={colWidths[1]}>
              <CampaignAnalysisTable
                title="Campaigns"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    text: "Campaigns",
                  },
                ]}
                onClick={(val) => onFirstColSelect(2)(val)}
                selecteValues={selectedCampaigns}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...selectedPlatforms.map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                ]}
                currentStep={step}
                step={1}
                onTitleClick={() => setStep(1)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
            <ColsCol width={colWidths[2]}>
              <CampaignAnalysisTable
                title="Content"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_CONTENT,
                    text: "Content",
                  },
                ]}
                onClick={(val) => onFirstColSelect(3)(val)}
                selecteValues={selectedGroups}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...selectedPlatforms.map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                  ...selectedCampaigns.map((sc) => ({
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    value: sc,
                  })),
                ]}
                currentStep={step}
                step={2}
                onTitleClick={() => setStep(2)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
            <ColsCol width={colWidths[3]}>
              <CampaignAnalysisTable
                title="Terms"
                firstCols={[
                  {
                    key: ApiDimension.DIMENSION_TERM,
                    text: "Terms",
                  },
                ]}
                selecteValues={selectedKeywords}
                conversionMetric={conversionMetric}
                setConversionMetric={setConversionMetric}
                attributionModel={attributionModel}
                setAttributionModel={setAttributionModel}
                publisherConversion={publisherConversion}
                setPublisherConversion={setPublisherConversion}
                filters={[
                  ...selectedPlatforms.map((sp) => ({
                    key: ApiDimension.DIMENSION_SOURCE,
                    value: sp,
                  })),
                  ...selectedCampaigns.map((sc) => ({
                    key: ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                    value: sc,
                  })),
                  ...selectedGroups.map((sg) => ({
                    key: ApiDimension.DIMENSION_CONTENT,
                    value: sg,
                  })),
                ]}
                currentStep={step}
                step={3}
                onTitleClick={() => setStep(3)}
                onMetricClick={onMetricClick}
              />
            </ColsCol>
          </ColsLayout>
        )}
      </Glass>
      <StyledDrawer
        anchor="right"
        open={openTrafficDrawer}
        onClose={() => setOpenTrafficDrawer(false)}
        hideDivider
      >
        <StyledDrawerTitle>
          <Typography variant="subtitle1">Explore Traffic</Typography>
          <Typography variant="body2">
            Explore traffic from Paid Platforms for your selected Provider /
            Campaign etc
          </Typography>
        </StyledDrawerTitle>
        <StyledDrawerContainer sx={{ minWidth: 1200 }}>
          <TrafficTable attributes={trafficDrawerAttributes} />
        </StyledDrawerContainer>
      </StyledDrawer>
    </>
  );
}

export default withPaidChannelAttributes(CampaignAnalysisExplorer);
