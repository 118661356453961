import { ApiProjectSettingKey, ApiProjectType } from "@incendium/api";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { StyledDrawerActions } from "Components/UI/StyledDrawer";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import { UtmMappingList } from "features/utms";
import { enumToArray, formatEnumVal } from "Helpers/enumToText";
import { TProjectSettings } from "Hooks";
import { useUTMs } from "Hooks/useUTMs";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { useCallback } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

interface IProjectOptionsDrawerFormProps {
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onClose?: () => void;
  projectSettings: TProjectSettings;
  setProjectSettings: (v: CallbackOrVal<TProjectSettings>) => void;
  showSettings?: [ApiProjectSettingKey];
  hideUTMS?: boolean;
  saveText?: string;
}

function ProjectOptionsDrawerForm({
  onSubmit,
  onClose,
  projectSettings,
  setProjectSettings,
  showSettings,
  hideUTMS,
  saveText,
}: IProjectOptionsDrawerFormProps) {
  const { utms, setUTMs } = useUTMs();

  const handleChange = useCallback(
    (key: ApiProjectSettingKey, value: string | null) => {
      setProjectSettings((settings) =>
        produce(settings, (draft) => {
          if (!value) {
            draft[key] = undefined;
          } else {
            draft[key] = value;
          }
        })
      );
    },
    [setProjectSettings]
  );

  const shouldShow = useCallback(
    (settings: ApiProjectSettingKey[]) =>
      !showSettings || showSettings.some((s) => settings.includes(s)),
    [showSettings]
  );

  return (
    <ValidatorForm onSubmit={onSubmit}>
      {shouldShow([
        ApiProjectSettingKey.BLOCKED_IPS,
        ApiProjectSettingKey.IGNORE_REFERRERS,
        ApiProjectSettingKey.PAGE_USE_QUERY_STRING,
        ApiProjectSettingKey.USE_DATALAYER_SALES,
      ]) && (
        <AccordianChartBuilderSidebarBlock
          title="Data Capture"
          subTitle="These settings affect the way we capture data."
        >
          <Stack spacing={3}>
            {shouldShow([ApiProjectSettingKey.BLOCKED_IPS]) && (
              <TextValidator
                variant="outlined"
                size="small"
                fullWidth
                label="Blocked IP's"
                helperText="Comma seperated list of ip's to block"
                name={ApiProjectSettingKey.BLOCKED_IPS}
                value={projectSettings[ApiProjectSettingKey.BLOCKED_IPS] || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(ApiProjectSettingKey.BLOCKED_IPS, e.target.value)
                }
              />
            )}
            {shouldShow([ApiProjectSettingKey.IGNORE_REFERRERS]) && (
              <TextValidator
                variant="outlined"
                size="small"
                fullWidth
                label="Referres to ignore"
                helperText="Comma seperated list of referer domains to ignore as a new session"
                name={ApiProjectSettingKey.IGNORE_REFERRERS}
                multiline
                rows={1}
                rowsMax={5}
                value={
                  projectSettings[ApiProjectSettingKey.IGNORE_REFERRERS] || ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(
                    ApiProjectSettingKey.IGNORE_REFERRERS,
                    e.target.value
                  )
                }
              />
            )}
            {shouldShow([ApiProjectSettingKey.PAGE_USE_QUERY_STRING]) && (
              <Stack>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={
                        projectSettings[
                          ApiProjectSettingKey.PAGE_USE_QUERY_STRING
                        ] === "true"
                      }
                      onChange={(e, checked) => {
                        handleChange(
                          ApiProjectSettingKey.PAGE_USE_QUERY_STRING,
                          checked ? "true" : "false"
                        );
                      }}
                    />
                  }
                  label="Capture Query Parameters"
                />
                <Typography variant="body2">
                  Query parameters are URL components used for passing data as
                  key-value pairs to a web server or application. If you do not
                  use query parameters to do thing such as change a product on a
                  page or change titles etc then you can turn this feature off.
                  Turning this feature off will improve crawling speeds and give
                  more accurate results.
                </Typography>
              </Stack>
            )}
            {shouldShow([ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS]) && (
              <Stack>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={
                        projectSettings[
                          ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS
                        ] === "true"
                      }
                      onChange={(e, checked) => {
                        handleChange(
                          ApiProjectSettingKey.USE_DATALAYER_PAGEVIEWS,
                          checked ? "true" : "false"
                        );
                      }}
                    />
                  }
                  label="Use DataLayer to track pageviews"
                />
                <Typography variant="body2">
                  This will set Incendium to use pre-existing Datalayer
                  implementations to track pageviews, it shall wtach for both
                  pageview and gtm.historyChange-v2 events.
                </Typography>
              </Stack>
            )}
            {shouldShow([ApiProjectSettingKey.USE_DATALAYER_SALES]) && (
              <Stack>
                <FormControlLabel
                  componentsProps={{
                    typography: {
                      variant: "body1",
                    },
                  }}
                  control={
                    <Switch
                      checked={
                        projectSettings[
                          ApiProjectSettingKey.USE_DATALAYER_SALES
                        ] === "true"
                      }
                      onChange={(e, checked) => {
                        handleChange(
                          ApiProjectSettingKey.USE_DATALAYER_SALES,
                          checked ? "true" : "false"
                        );
                      }}
                    />
                  }
                  label="Use DataLayer for Ecommerce"
                />
                <Typography variant="body2">
                  This will set Incendium to use pre-existing Datalayer
                  implementations to track ecommerce.
                </Typography>
              </Stack>
            )}
          </Stack>
        </AccordianChartBuilderSidebarBlock>
      )}
      {shouldShow([ApiProjectSettingKey.FB_PIXEL]) && (
        <AccordianChartBuilderSidebarBlock
          title="Facebook / Meta Pixel"
          subTitle="Add your pixel number, this will unlock options such as pushing custom data back to your meta ads account"
        >
          <TextValidator
            variant="outlined"
            size="small"
            fullWidth
            label="Pixel Id"
            helperText="Copy from Events Manager"
            name={ApiProjectSettingKey.FB_PIXEL}
            value={projectSettings[ApiProjectSettingKey.FB_PIXEL] || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(ApiProjectSettingKey.FB_PIXEL, e.target.value)
            }
          />
        </AccordianChartBuilderSidebarBlock>
      )}
      {shouldShow([
        ApiProjectSettingKey.RETENTION_MAX_CHANCE,
        ApiProjectSettingKey.RETENTION_LIFETIME_MONTHS,
        ApiProjectSettingKey.RETENTION_GRACE_PERIOD_MONTHS,
      ]) && (
        <AccordianChartBuilderSidebarBlock
          title="Retention"
          subTitle=" Our machine learning model predicts the likelihood of a user making
    a repeat purchase, and we categorize a lead as 'at risk' if their
    chance of converting falls below a certain percentage (max chance),
    and if their last purchase was not made within the past (grace
    period) months, and it has been (lifetime) months since their last
    purchase. These settings enable you to customize this prediction and
    categorization process."
        >
          <Stack spacing={3}>
            {shouldShow([ApiProjectSettingKey.RETENTION_MAX_CHANCE]) && (
              <TextValidator
                variant="outlined"
                size="small"
                fullWidth
                label="Max Chance"
                type="number"
                min={0}
                max={100}
                validators={["minNumber:0", "maxNumber:100"]}
                errorMessages={[
                  "Max Chance must be between 0 and 100",
                  "Max Chance must be between 0 and 100",
                ]}
                helperText="The maximum chance of conversion for a lead before they are considered `at risk`"
                name={ApiProjectSettingKey.RETENTION_MAX_CHANCE}
                value={
                  projectSettings[ApiProjectSettingKey.RETENTION_MAX_CHANCE] ||
                  ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(
                    ApiProjectSettingKey.RETENTION_MAX_CHANCE,
                    e.target.value
                  )
                }
              />
            )}
            {shouldShow([ApiProjectSettingKey.RETENTION_LIFETIME_MONTHS]) && (
              <TextValidator
                variant="outlined"
                size="small"
                fullWidth
                label="Lifetime"
                type="number"
                min={1}
                validators={["minNumber:1"]}
                errorMessages={["Lifetime must be a minimum of 1 month"]}
                helperText="The Lifetime of a lead is determined by the number of months elapsed since their last visit, after which they are considered inactive or 'dead'."
                name={ApiProjectSettingKey.RETENTION_LIFETIME_MONTHS}
                value={
                  projectSettings[
                    ApiProjectSettingKey.RETENTION_LIFETIME_MONTHS
                  ] || ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(
                    ApiProjectSettingKey.RETENTION_LIFETIME_MONTHS,
                    e.target.value
                  )
                }
              />
            )}
            {shouldShow([
              ApiProjectSettingKey.RETENTION_GRACE_PERIOD_MONTHS,
            ]) && (
              <TextValidator
                variant="outlined"
                size="small"
                fullWidth
                label="Grace Period"
                type="number"
                min={0}
                validators={["minNumber:0"]}
                errorMessages={["Grace period must be a minimum of 0 months"]}
                helperText="The Grace period refers to the number of months since a lead's last transaction that we overlook their inactivity before considering them at risk."
                name={ApiProjectSettingKey.RETENTION_GRACE_PERIOD_MONTHS}
                value={
                  projectSettings[
                    ApiProjectSettingKey.RETENTION_GRACE_PERIOD_MONTHS
                  ] || ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(
                    ApiProjectSettingKey.RETENTION_GRACE_PERIOD_MONTHS,
                    e.target.value
                  )
                }
              />
            )}
          </Stack>
        </AccordianChartBuilderSidebarBlock>
      )}
      {shouldShow([
        ApiProjectSettingKey.PROJECT_TYPE,
        ApiProjectSettingKey.SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS,
      ]) && (
        <AccordianChartBuilderSidebarBlock
          title="Analyse"
          subTitle={
            <>
              <Typography variant="body2">
                These settings enable customization of your analysis section.
              </Typography>
              <Typography variant="body2">
                Important: You must select either{" "}
                {formatEnumVal(
                  ApiProjectType.TYPE_ECOMMERCE.replace("Type", "")
                )}{" "}
                or{" "}
                {formatEnumVal(
                  ApiProjectType.TYPE_RECRUITMENT.replace("Type", "")
                )}{" "}
                if you wish to use impressions.
              </Typography>
            </>
          }
        >
          <Stack spacing={3}>
            <Autocomplete
              options={enumToArray(ApiProjectType).filter(
                (s) => s !== ApiProjectType.TYPE_UNSPECIFIED
              )}
              getOptionLabel={(o) => formatEnumVal(o.replace("Type", ""))}
              value={projectSettings[ApiProjectSettingKey.PROJECT_TYPE] || ""}
              onChange={(e, v) => {
                handleChange(ApiProjectSettingKey.PROJECT_TYPE, v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project Type"
                  helperText="Customize your analytics experience by selecting a project type (e.g., ecom or b2b). This will dynamically change the reports shown to match your selection and update the available tagging options accordingly."
                />
              )}
            />
            <Stack>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "body1",
                  },
                }}
                control={
                  <Switch
                    checked={
                      projectSettings[
                        ApiProjectSettingKey
                          .SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS
                      ] === "true"
                    }
                    onChange={(e, checked) => {
                      handleChange(
                        ApiProjectSettingKey.SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS,
                        checked ? "true" : "false"
                      );
                    }}
                  />
                }
                label="Show Awareness, Aquisition and Retention insights block in reports"
              />
              <Typography variant="body2">
                This block shows insights in these funnel stages based on
                configuration of Reserved Traffic Buckets.
              </Typography>
            </Stack>
          </Stack>
        </AccordianChartBuilderSidebarBlock>
      )}
      {!hideUTMS && (
        <AccordianChartBuilderSidebarBlock
          title="Custom UTM Mapping"
          subTitle="Customize UTM mappings by overriding default settings. This can be done on a per-publisher basis or by creating one or multiple catch-all configurations. Our system will search through each mapping until the correct one is found."
        >
          <UtmMappingList utms={utms} setUTMs={setUTMs} showProvider />
        </AccordianChartBuilderSidebarBlock>
      )}
      <StyledDrawerActions justifyContent={"flex-end"}>
        {onClose && (
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
        )}
        <Button variant="contained" color="primary" type="submit">
          {saveText || "Save"}
        </Button>
      </StyledDrawerActions>
    </ValidatorForm>
  );
}

export default ProjectOptionsDrawerForm;
