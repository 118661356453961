/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiProjectSettingKey;
(function (ApiProjectSettingKey) {
    ApiProjectSettingKey["SETTING_UNSPECIFIED"] = "SETTING_UNSPECIFIED";
    ApiProjectSettingKey["BLOCKED_IPS"] = "BLOCKED_IPS";
    ApiProjectSettingKey["ENGAGEMENT_SCORE"] = "ENGAGEMENT_SCORE";
    ApiProjectSettingKey["IGNORE_REFERRERS"] = "IGNORE_REFERRERS";
    ApiProjectSettingKey["LEAD_SCORE_MAX"] = "LEAD_SCORE_MAX";
    ApiProjectSettingKey["RETENTION_LIFETIME_MONTHS"] = "RETENTION_LIFETIME_MONTHS";
    ApiProjectSettingKey["RETENTION_MAX_CHANCE"] = "RETENTION_MAX_CHANCE";
    ApiProjectSettingKey["RETENTION_GRACE_PERIOD_MONTHS"] = "RETENTION_GRACE_PERIOD_MONTHS";
    ApiProjectSettingKey["PAGE_USE_QUERY_STRING"] = "PAGE_USE_QUERY_STRING";
    ApiProjectSettingKey["SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS"] = "SHOW_AWARENESS_ACQUISTION_RETENTION_INSIGHTS";
    ApiProjectSettingKey["PROJECT_TYPE"] = "PROJECT_TYPE";
    ApiProjectSettingKey["USE_DATALAYER_SALES"] = "USE_DATALAYER_SALES";
    ApiProjectSettingKey["FB_PIXEL"] = "FB_PIXEL";
    ApiProjectSettingKey["UI_STATE"] = "UI_STATE";
    ApiProjectSettingKey["USE_DATALAYER_PAGEVIEWS"] = "USE_DATALAYER_PAGEVIEWS";
    ApiProjectSettingKey["USE_DATALAYER_CART_EVENTS"] = "USE_DATALAYER_CART_EVENTS";
    ApiProjectSettingKey["USE_DATALAYER_CHECKOUT_EVENTS"] = "USE_DATALAYER_CHECKOUT_EVENTS";
})(ApiProjectSettingKey || (ApiProjectSettingKey = {}));
export function ApiProjectSettingKeyFromJSON(json) {
    return ApiProjectSettingKeyFromJSONTyped(json, false);
}
export function ApiProjectSettingKeyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiProjectSettingKeyToJSON(value) {
    return value;
}
