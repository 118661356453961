import { KeyboardTab } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import React from "react";

export const StyledDrawerContainer = styled(Box)(({ theme }) => ({}));
StyledDrawerContainer.defaultProps = {
  py: 2,
  px: 3,
};

export const StyledDrawerActions = styled(Stack)(({ theme }) => ({}));
StyledDrawerActions.defaultProps = {
  direction: "row",
  spacing: 2,
  py: 2,
  px: 3,
};

export const StyledDrawerTitle = styled(Box)(({ theme }) => ({
  flex: 1,
}));

interface IStyledDrawerProps extends DrawerProps {
  maxWidth?: number;
  hideDivider?: boolean;
}

function StyledDrawer({
  open,
  onClose,
  children,
  maxWidth,
  hideDivider,
}: IStyledDrawerProps) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ maxWidth: maxWidth || undefined }}>
        <StyledDrawerContainer>
          <Stack direction="row" spacing={3} alignItems="center">
            <Box>
              <IconButton
                onClick={(e) => onClose && onClose(e, "backdropClick")}
              >
                <KeyboardTab />
              </IconButton>
            </Box>

            {React.Children.toArray(children).filter((child) => {
              if (!React.isValidElement(child)) {
                return false;
              }
              return child.type === StyledDrawerTitle;
            })}
          </Stack>
        </StyledDrawerContainer>
        {!hideDivider && <Divider />}
        {React.Children.toArray(children).filter((child) => {
          if (!React.isValidElement(child)) {
            return false;
          }
          return child.type !== StyledDrawerTitle;
        })}
      </Box>
    </Drawer>
  );
}

export default StyledDrawer;
